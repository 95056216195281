import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import {
  fetchFilesByEmail, getFileWithSasUrl, checkConvertedFile, checkRunningBatchTranscription, checkRunningBatchReTranscription,
  deleteAudio, UpsertTranscriptionWord, InsertCustomButton, UpdateCustomButton, SendtoAzureChat, GetCustomButtons,
  deleteCustomButton, deleteTranscriptionWord, getTranscriptionDetailsByTimestamp, getChatHistory
} from "services/api";
import UploadFile from "components/Modal/UploadFile";
//import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { FormControl, InputLabel, Select } from "@material-ui/core";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AudioPlayer from 'react-h5-audio-player';
import Tooltip from '@material-ui/core/Tooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Tab, Tabs } from "@material-ui/core";
import { TextField, Checkbox, Link } from "@material-ui/core";
import Avatar from '@material-ui/core/Avatar';
import { Check, CloudUpload, Undo, Edit, Close } from "@material-ui/icons";
//import CloseIcon from '@material-ui/icons/Close';
//import { Howl } from 'howler';
import 'react-h5-audio-player/lib/styles.css';
import '../../assets/css/audiolystyles.css';
import '../../assets/css/styles.scss'
import { useAuth } from '../../services/authContext';
//import { reTranscribe } from "services/api";
//import { checkTranscriptionStatus} from "services/api";
import PropTypes from 'prop-types';
//import { ChatBubbleOutline as ChatBubbleOutlineIcon, FeedbackOutlined as FeedbackOutlinedIcon, HelpOutline as HelpOutlineIcon, LightbulbOutline as LightbulbOutlineIcon, Send as SendIcon } from '@material-ui/icons';
//import HelpOutlineIcon  from '@material-ui/icons';
//import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline'; // Use this for chat
import SendIcon from '@material-ui/icons/Send';
import FeedbackOutlinedIcon from '@material-ui/icons/FeedbackOutlined'; // Use this for feedback
import ReactMarkdown from 'react-markdown';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import DownloadTranscirption from "components/Modal/DownloadTranscirption";
import ReTranscribe from "components/Modal/ReTranscribe";
import MoveFile from "components/Modal/MoveFile";
import useTokenExpirationCheck from "hooks/useTokenExpirationCheck";
import {getCookie} from "components/Utils/cookies";


const iconList = [
  { name: 'Question', icon: <HelpOutlineIcon /> },
  { name: 'Chat', icon: <ChatBubbleOutlineIcon /> },
  { name: 'Send', icon: <SendIcon /> },
  { name: 'Feedback', icon: <FeedbackOutlinedIcon /> },
  { name: 'Add', icon: <AddIcon /> },
  { name: 'Edit', icon: <EditIcon /> },
  { name: 'Delete', icon: <DeleteIcon /> },
  { name: 'Download', icon: <GetAppIcon /> },
  { name: 'Upload', icon: <CloudUpload /> },
  { name: 'Undo', icon: <Undo /> }];
const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  }
};

const useStyles = makeStyles(styles);
//const CHUNK_SIZE = 1 * 1024 * 1024; // 1MB per chunk (adjust as needed)

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
// Add PropTypes validation for TabPanel component
TabPanel.propTypes = {
  children: PropTypes.node,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function TableList() {
  useTokenExpirationCheck();
  const { showExpiredModal, handleModalCloseTokenExpired } = useTokenExpirationCheck();
  const [value, setValue] = useState(0);
  const [selectedIcon, setSelectedIcon] = useState('');
  const [questionText, setQuestionText] = useState('');
  const [customButtons, setCustomButtons] = useState([]);
  const [modalOpenNewCustomButton, setNewCustomModalButton] = useState(false);
  //const [suggestions, setSuggestions] = useState([]);
  //const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [isSuggested, setIsSuggested] = useState(false);
  // const [chatResponse, setChatResponse] = useState("");
  const { user } = useAuth();
  const classes = useStyles();
  const [tableData, setTableData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpenSpeaker, setModalOpenSpeaker] = useState(false);
  // const [modalUpload, setModalUpload] = useState(false);
  const [modalUploadv2, setModalUploadv2] = useState(false);
  const [modalRetranscribe, setModalRetranscribe] = useState(false);
  //const [modalConfirmation, setModalConfirmation] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElSpeaker, setAnchorElSpeaker] = useState(null);
  const [selectedSegmentIndex, setSelectedSegmentIndex] = useState(null);
  //const [speakerName, setSpeakerName] = useState("");
  const [speakerName, setSpeakerName] = useState('');
  const [transcriptionData, setTranscriptionData] = useState("");
  //const [transcriptionDataDownload, setTranscriptionDataDownload] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [audioUrl, setAudioUrl] = useState(""); // State to hold audio URL
  const [isEditing, setIsEditing] = useState(false);
  const [editableText, setEditableText] = useState(transcriptionData);
  const [isParagraphEditing, setIsParagraphEditing] = useState(false);
  const [editableParagraph, setEditableParagraph] = useState(transcriptionData);
  const [saveBntDisabled, setsaveBntDisabled] = useState(false);

  const [isSaving, setIsSaving] = useState(false);
  const [isSingleSpeaker, setSingleSpeaker] = useState(false);
  const [isMutlipleSpeaker, setMutlipleSpeaker] = useState(false);
  const [refreshTrigger, setRefreshTrigger] = useState(false); // Trigger to re-fetch data
  //const [uploadProgress, setUploadProgress] = useState(0);
  const [highlightedSegmentIndex, setHighlightedSegmentIndex] = useState(null);
  //const [showProgressBar, setShowProgressBar] = useState(true);
  // const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // const [audioContext, setAudioContext] = useState(null);
  const [audioBuffer, setAudioBuffer] = useState(null);
  // const [source, setSource] = useState(null);
  //const [isUploading, setUpLoading] = useState(false);
  const audioPlayerRef = useRef(null);
  const audioContextRef = useRef(null);
  // const dropzoneRef = useRef(null);
  const spanRef = useRef(null);
  const inputRef = useRef(null);
  const paragraphInputRef = useRef(null);
  const [selectedEditText, setEditValue] = useState(null);
  const [selectedSegmentIndexv2, setSelectedSegmentIndexv2] = useState(null);
  //const [models, setModels] = useState([]); // State to hold models
  //const [loading, setLoading] = useState(false); // Loading state
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [openSuccessDeleteDialog, setOpenSuccessDeleteDialog] = useState(false);
  const [showTranscribeTextTimestamp, setshowTranscribeTextTimestamp] = useState(false);
  const [messages, setMessages] = useState([]);
  const [isResponseChat, setisResponseChat] = useState(false);
  const [isOpenDownloadModal, setOpenDownloadModal] = useState(false);
  const [isOpenMoveFileModal, setOpenMoveFileModal] = useState(false);
  const transcriptionContainerRef = useRef(null);

  const [contextMenu, setContextMenu] = useState({
    mouseX: null,
    mouseY: null,
    button: null,
  });
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [editButton, setEditButton] = useState(null);
  const [newButtonText, setNewButtonText] = useState('');
  const messageEndRef = useRef(null);
  // let audioContext = null;
  //let audioBuffer = null;
  let source = null;
  let isRestoreBtnClicked = false;
  //const isLoading = useRef(false);

  //------------------------download transcript-----------------------------------

  const downloadModal = () => {
    setOpenDownloadModal(true);
  }
  const handleModalDownloadClose = () => {
    setOpenDownloadModal(false);
  }

  // ----------------------------Customize------------------------------------------------------- 
  const handleTabChange = (event, newValue) => {
    console.log('Tab Value:', newValue);
    setValue(newValue);
    if (newValue === 1) {
      scrollToBottom();
    }
  };
  const handleIconChange = (event) => {
    setSelectedIcon(event.target.value);
  };


  // Scroll to the bottom when messages change
  const scrollToBottom = () => {
    messageEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  // Use useEffect to trigger the scroll whenever the messages array changes
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleTextChange = (event) => {
    const inputValue = event.target.value;
    setQuestionText(inputValue);
    //console.log('suggestions:', suggestions);
    // Filter suggestions based on input
    if (inputValue) {
      // const filtered = suggestions.filter((btn) =>
      //   btn.text && btn.text.toLowerCase().includes(inputValue.toLowerCase())
      // );
      //console.log('Filtered Suggestions:', filtered);
      //setFilteredSuggestions(filtered);
    } else {
      //setFilteredSuggestions([]);
    }
  };
  const handleCreateNewCustomButton = () => {
    setNewCustomModalButton(true);
  };
  const handleCreateButton = async () => {
    if (selectedIcon && questionText) {
      //console.log('Selected Icon:', selectedIcon);
      const selectedIconObj = iconList.find((iconObj) => iconObj.name === selectedIcon);
      const newButton = { icon: selectedIconObj.icon, text: questionText };

      // Add button to customButtons array
      setCustomButtons((prevButtons) => [...prevButtons, newButton]);
      setSelectedIcon('');
      setQuestionText('');
      //console.log('Selected File', selectedFile.transcriptions[0].id);
      const transId = selectedFile.transcriptions[0].id;
      const payload = {
        TranscriptionId: transId,
        Icon: selectedIconObj.name,
        QuestionText: questionText,
        Email: user.email,
      };

      try {
        if (!isSuggested) {
          try {
            await InsertCustomButton(payload);
          } catch (error) {
            console.error('Error creating button:', error);
            // Optionally set an error message state to inform the user
          }
        }
      } catch (error) {
        console.error('Error creating button:', error);
        // Optionally set an error message state to inform the user
      }
      setIsSuggested(false);
      setNewCustomModalButton(false);
    }
  };

  // const handleSuggestionClick = (btn) => {

  //   console.log('Selected Button:', btn);

  //   setQuestionText(btn.text);
  //   setSelectedIcon(btn.icon); // Assuming btn has an icon property
  //   setIsSuggested(true);
  //   handleCreateButton();
  //   setFilteredSuggestions([]);
  // };

  const handleButtonClick = async (btn) => {
    setisResponseChat(true);
    try {
      const transId = selectedFile.transcriptions[0].id;
      const userMessage = {
        type: 'user',
        content: btn.text,
        timestamp: new Date(),
      };
      setMessages((prevMessages) => [...prevMessages, userMessage]);
      const result = await SendtoAzureChat(transId, btn.text, user.email);
      //console.log('Azure Chat Response:', result);
      const formattedResponse = formatAzureResponseToMarkdown(result.data.response);
      const azureMessage = {
        type: 'azure',
        question: result.data.question,
        response: formattedResponse,
        timestamp: new Date(),
      };
      setMessages((prevMessages) => [...prevMessages, azureMessage]);
    } catch (error) {
      setisResponseChat(false);
    }
    finally {
      setisResponseChat(false);
    }
  }
  const handleModalCloseNewCustomButton = () => {
    setNewCustomModalButton(false);
  };
  //  format a response from Azure to markdown format here is the link guide = https://www.markdownguide.org/basic-syntax/
  const formatAzureResponseToMarkdown = (response) => {
    let formattedResponse = response;

    formattedResponse = formattedResponse.replace(/(\*\*|__)(.*?)\1/g, '**$2**');

    formattedResponse = formattedResponse.replace(/(\*|_)(.*?)\1/g, '*$2*');

    formattedResponse = formattedResponse.replace(/(#+)\s(.+)/g, (match, hashes, text) => {
      const level = hashes.length;
      return `${'#'.repeat(level)} ${text}`;
    });

    formattedResponse = formattedResponse.replace(/^(\d+)\.\s(.+)/gm, '$1. $2');

    formattedResponse = formattedResponse.replace(/^\s*[-*]\s(.+)/gm, '- $1');

    formattedResponse = formattedResponse.replace(/\[([^\]]+)\]\(([^)]+)\)/g, '[$1]($2)');

    formattedResponse = formattedResponse.replace(/`([^`]+)`/g, '`$1`');

    formattedResponse = formattedResponse.replace(/```([^`]+)```/gs, '```\n$1\n```');

    return formattedResponse;
  };

  const handleEditDeleteCustomButton = async (event, btn, index) => {
    console.log('Button:', btn);
    console.log('index:', index);
    setNewButtonText(btn.text);
    setEditButton({ btn: btn, index: index });
    event.preventDefault();
    setContextMenu({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
      button: btn,
    });
  }
  // Open the edit modal
  const handleEdit = () => {
    console.log('Edit Button:', editButton);
    setEditButton(contextMenu.button);
    setEditModalOpen(true);
    handleCloseContextMenu();
  };

  // Close the context menu
  const handleCloseContextMenu = () => {
    setContextMenu({
      mouseX: null,
      mouseY: null,
      button: null,
    });
  };
  const handleDelete = async () => {
    handleCloseContextMenu();
    setCustomButtons((prevButtons) => prevButtons.filter((_, i) => i !== editButton.index));
    //console.log('Custom Buttons:', customButtons[index].id);
    const id = editButton.btn.id
    await deleteCustomButton(id);

  }
  const handleSaveEdit = async () => {
    console.log('Edit Button:', editButton);
    console.log('New Button Text:', newButtonText);
    const id = editButton.id;
    console.log('ID:', id);
    const transId = selectedFile.transcriptions[0].id;
    const payload = {
      id: id,
      TranscriptionId: transId,
      Icon: "",
      QuestionText: newButtonText,
      Email: user.email,
    };
    try {
      setEditModalOpen(false);
      const response = await UpdateCustomButton(payload);
      if (response && response.status === 200) {
        // Re-fetch the updated list of custom buttons after update
        const savedButtons = await GetCustomButtons(user.email);
        const newSavedButtons = savedButtons.map((btn) => {
          const selectedIconObj = iconList.find((iconObj) => iconObj.name === btn.icon);
          return { icon: selectedIconObj ? selectedIconObj.icon : null, text: btn.text, id: btn.id };
        });
        setCustomButtons(newSavedButtons);
      }
    } catch (error) {
      setEditModalOpen(false);
      console.error("Error updating the button", error);
    }

  }
  const handleCloseEditModal = () => {
    setEditModalOpen(false);
  }
  // const removeButton = async (index) => {
  //   //console.log('Removing button:', index);
  //   setCustomButtons((prevButtons) => prevButtons.filter((_, i) => i !== index));
  //   //console.log('Custom Buttons:', customButtons[index].id);
  //   const id = customButtons[index].id
  //   await deleteCustomButton(id);
  // };

  const fetchChatHistory = async (transcriptionId) => {
    const chatHistory = await getChatHistory(transcriptionId, user.email);
    console.log('Chat History:', chatHistory.conversationHistory);
   
    if (chatHistory.conversationHistory && chatHistory.conversationHistory.length > 0) {
      // Collect new messages in an array
      const newMessages = [];
      console.log('newMessages:');
      chatHistory.conversationHistory.forEach((history) => {
        if (history.role === 'user') {
          newMessages.push({
            type: 'user',
            content: history.text,
            timestamp: history.timeStamp,
          });
        } else if (history.role === 'assistant') {
          const formattedResponse = formatAzureResponseToMarkdown(history.text);
          newMessages.push({
            type: 'azure',
            question: '',
            response: formattedResponse,
            timestamp: history.timeStamp,
          });
        }
      });
      console.log('newMessages:', newMessages);
      // Append the new messages to the current messages state
      setMessages(newMessages);
      scrollToBottom();
    }
  }

  // ----------------------------------------------------------------------------------- 

  const formatDate = (dateString) => {
    try {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
      const day = String(date.getDate()).padStart(2, '0');

      return `${year}-${month}-${day}`;
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Invalid date';
    }
  };
  const formatDuration = (durationInSeconds) => {
    // Ensure the duration is a number
    const duration = Number(durationInSeconds);

    if (isNaN(duration) || duration < 0) {
      return 'Invalid duration';
    }

    // Calculate minutes and seconds
    const minutes = Math.floor(duration / 60);
    const seconds = Math.round(duration % 60);

    // Format minutes and seconds with leading zeroes if necessary
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(seconds).padStart(2, '0');

    return `${formattedMinutes}:${formattedSeconds}`;
  };

  const handleTimeUpdate = () => {
    //console.log('TranscriptionData:', transcriptionData);
    if (audioPlayerRef.current && audioPlayerRef.current.audio && transcriptionData.length > 0) {
      const currentTime = audioPlayerRef.current.audio.current.currentTime;
      const timeBuffer = 0.05; // Allow for a 0.05 second tolerance
      let currentSegment = null;
      // Loop through each transcription segment

      for (let i = 0; i < transcriptionData.length; i++) {
        const words = transcriptionData[i].words;
        // Find the word within the segment that matches the current time
        currentSegment = words.find(
          (word) => currentTime >= word.startTime - timeBuffer && currentTime <= word.endTime + timeBuffer
        );

        // If a matching word is found, break out of the loop
        if (currentSegment) {
          setHighlightedSegmentIndex({ startTime: currentSegment.startTime, endTime: currentSegment.endTime }); // Pass an object // or use another unique identifier like startTime
          break;
        }
      }

      // If no segment is found, reset the highlight
      if (!currentSegment) {
        console.log('No active word found at the current time.');
        setHighlightedSegmentIndex(null);
      }
    }
  };

  // const UploadModal = () => {
  //   //setModalUpload(true);
  //   fetchModels();
  // }
  const UploadModalV2 = () => { setModalUploadv2(true); }

  const handleModalUploadCloseV2 = () => {
    console.log('Modal Upload V2 Closed');
    setModalUploadv2(false);
  }

  const handleChange = (event) => {
    //console.log('Editable text:', event.target.value);
    setEditableText(event.target.value);
  };

  const handleParagraphChange = (event) => {
    setEditableParagraph(event.target.value);
  };


  const editSpeaker = (segment, wordIndex, event) => {
    event.preventDefault();
    //console.log('segmentIndex text:', segment, 'wordIndex:', wordIndex);

    setSelectedSegmentIndex({ segment, wordIndex });  // Set both segment and wordIndex in state
    setSpeakerName(segment.speakerOnly);  // Set the speaker name for the modal
    setAnchorElSpeaker(event.currentTarget);  // Set the anchor element for the menu

    // Log the updated state (Note: state won't immediately reflect here due to React's asynchronous state updates)
    //console.log('Updated selectedSegmentIndex:', { segment, wordIndex });
  };

  const handleSingleSpeakeModal = () => {
    setSingleSpeaker(true);
    setModalOpenSpeaker(true);
    setMutlipleSpeaker(false);
    handleClose();
  }
  const handleMultipleSpeakeModal = () => {
    setMutlipleSpeaker(true);
    setModalOpenSpeaker(true);
    setSingleSpeaker(false);
    handleClose();
  }

  const handleMultipleSpeakerEdit = async () => {
    if (!speakerName) {
      console.error("No speaker name provided for edit");
      return;
    }
    // Deep clone the transcription data to avoid mutation
    const updatedTranscriptionData = JSON.parse(JSON.stringify(transcriptionData));
    // Extract the segment from the selected index
    const { segment } = selectedSegmentIndexv2;
    if (!segment || !segment.speakerOnly) {
      console.error("No valid segment or speakerOnly in selectedSegmentIndexv2");
      return;
    }
    let updateCount = 0; // To track how many updates we perform
    // Find and update all segments that match the current speaker label
    updatedTranscriptionData.forEach((transcriptionData, index) => {
      console.log(`Transcription speakerOnly [${index}]:`, transcriptionData.speakerOnly);
      console.log("Segment speakerOnly:", segment.speakerOnly);

      if (transcriptionData.speakerOnly === segment.speakerOnly) {
        console.log(`Updating segment for speaker: ${segment.speakerOnly} to ${speakerName}`);
        transcriptionData.speakerOnly = speakerName; // Assign the new speaker name
        updateCount++;
      }
    });

    if (updateCount === 0) {
      console.log("No segments were updated.");
    } else {
      console.log(`${updateCount} segments updated successfully.`);
    }
    setTranscriptionData(updatedTranscriptionData);
    // Build the fullRawDetails with the updated speaker name
    const fullRawDetails = updatedTranscriptionData
      .map((seg) => {
        const segmentText = seg.speakerText || seg.words.map((word) => word.word).join(" ");

        const wordsWithTime = seg.words
          .map((word) => `${word.word} [${word.startTime}s - ${word.endTime}s]`)
          .join(", ");

        return `${seg.speakerOnly}: ${segmentText} <ts#0x1>${wordsWithTime}</ts#0x1>`;
      })
      .join("\n");

    //console.log("Full Raw Details after speaker edit by label:", fullRawDetails);
    // Uncomment the save logic if needed
    const id = selectedFile.transcriptions[0].id; // Ensure you have the correct ID
    await saveUpdatedTranscription(id, fullRawDetails);
    setRefreshTrigger(prev => !prev);
    setModalOpenSpeaker(false);
    handleClose(); // Close the menu after action
  };

  const handleSingleSpeakerEdit = async () => {
    if (selectedSegmentIndexv2) {
      // Clone the transcription data
      const updatedTranscriptionData = JSON.parse(JSON.stringify(transcriptionData));
      const { segment, wordIndex } = selectedSegmentIndexv2;
      // check if the indexArray is matched with the updatedTranscriptionData index
      // Find the index of the segment using the wordIndex
      const segmentIndex = updatedTranscriptionData.findIndex((seg, index) =>
        seg.speakerOnly === segment.speakerOnly && index === wordIndex
      );
      // Check if the segmentIndex is valid
      if (segmentIndex !== -1) {
        // Update the speaker's name for the identified segment
        updatedTranscriptionData[segmentIndex].speakerOnly = speakerName;

        setTranscriptionData(updatedTranscriptionData);

        const fullRawDetails = updatedTranscriptionData
          .map((seg) => {
            // Construct text from words if segment.text is undefined
            const segmentText = seg.text || seg.words.map(word => word.word).join(' ');

            const wordsWithTime = seg.words
              .map((word) => `${word.word} [${word.startTime}s - ${word.endTime}s]`)
              .join(', ');

            return `${seg.speakerOnly}: ${segmentText} <ts#0x1>${wordsWithTime}</ts#0x1>`;
          })
          .join('\n');

        //console.log('Full Raw Details:', fullRawDetails);

        // Assuming you have a save function to handle this
        const id = selectedFile.transcriptions[0].id; // Ensure you have the correct ID
        await saveUpdatedTranscription(id, fullRawDetails); // Uncomment your save logic
        setRefreshTrigger(prev => !prev);
        setModalOpenSpeaker(false);
      } else {
        setModalOpenSpeaker(false);
        console.error('Segment not found for the selected index');
      }

      handleClose(); // Close the menu after action
    }
  };

  // When editing is done and focus leaves the input
  const handleDoubleClick = (segmentIndex, wordIndex, startTime, endTime, event) => {
    event.preventDefault();

    const segment = transcriptionData[segmentIndex].words.find(
      (word) => word.startTime === startTime && word.endTime === endTime
    );

    if (segment) {
      setIsEditing({ segmentIndex, wordIndex, startTime, endTime }); // Track both segment and word index
      //console.log('Editable text:',segment.word);
      setEditableText(segment.word); // Set the current word in the editable field
      //setEditableText(transcriptionData[index].text);

      setEditValue(segment.word);
    } else {
      console.log('Segment not found');
    }
  };
  const handleBlur = async () => {
    if (isSaving) return; // Prevent multiple calls
    setIsSaving(true);

    if (isEditing !== null && selectedFile && selectedFile.transcriptions.length > 0) {
      const updatedTranscriptionData = [...transcriptionData];
      //console.log('Updated Transcription Data:', updatedTranscriptionData);
      // Update the word in the transcription data

      let transcriptionWordId = 0;
      const id = selectedFile.transcriptions[0].id;
      let originalWord = updatedTranscriptionData[isEditing.segmentIndex].words[isEditing.wordIndex].originalWord;

      originalWord = originalWord == '' ? updatedTranscriptionData[isEditing.segmentIndex].words[isEditing.wordIndex].word : originalWord;

      const combinedTimestamp = '[' + updatedTranscriptionData[isEditing.segmentIndex].words[isEditing.wordIndex].startTime + ' - ' + updatedTranscriptionData[isEditing.segmentIndex].words[isEditing.wordIndex].endTime + ']'

      if (selectedFile.transcriptions[0].transcriptionWords.length > 0) {
        let transcriptionWordObj = selectedFile.transcriptions[0].transcriptionWords.filter(f => f.originalWord == originalWord && f.originalTimestamp == combinedTimestamp);

        if (transcriptionWordObj.length > 0) {
          transcriptionWordId = transcriptionWordObj[0].id;
        }
      }

      const payloadTranscriptionWord =
      {
        id: transcriptionWordId,
        transcriptionId: id,
        originalWord: originalWord,
        originalTimestamp: combinedTimestamp,
        customWord: editableText,
        customTimestamp: combinedTimestamp,
        email: user.email,
        restore: isRestoreBtnClicked
      }

      updatedTranscriptionData[isEditing.segmentIndex].words[isEditing.wordIndex].word = editableText;

      // Build the fullRawDetails with speaker and words along with their time segments in the format:
      const fullRawDetails = updatedTranscriptionData
        .map((segment) => {
          // Construct text from words if segment.text is undefined
          const segmentText = segment.text || segment.words.map(word => word.word).join(' ');

          const wordsWithTime = segment.words
            .map((word) => `${word.word} [${word.startTime}s - ${word.endTime}s]`)
            .join(', ');

          return `${segment.speakerOnly}: ${segmentText} <ts#0x1>${wordsWithTime}</ts#0x1>`;
        })
        .join('\n');
      //console.log('Full Raw Details:', fullRawDetails);
      // Assuming you have a save function to handle this
      let result = await UpsertTranscriptionWord(payloadTranscriptionWord);
      await saveUpdatedTranscription(id, fullRawDetails); // Uncomment your save logic

      const transcriptionWords = result.data;
      const parsedSegments = parseTranscription(fullRawDetails, transcriptionWords);

      setTranscriptionData(parsedSegments);

      // Trigger refresh
      setRefreshTrigger(prev => !prev);

      setIsEditing(null);
    }

    setIsSaving(false); // Reset saving flag
  };

  const saveUpdatedTranscription = async (id, updatedRawDetails) => {
    try {
      const token = getCookie('jwtToken');
      const response = await fetch(process.env.REACT_APP_API_URL + "/api/Transcription/UpdateTranscriptionRawDetails", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          id: id,
          rawDetails: updatedRawDetails,
        }),
      });

      if (response.ok) {
        //alert("Transcription updated successfully!");
        setRefreshTrigger(prev => !prev);
        setIsEditing(false);
      } else {
        alert("Failed to update transcription.");
      }
    } catch (error) {
      console.error("Error updating transcription:", error);
    }
  };

  const loadAudioFile = async (audioUrl) => {
    //console.log('Loading audio file:', audioUrl);

    if (!audioContextRef.current) {
      try {
        audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
      } catch (e) {
        console.error('Error creating audio context:', e);
        return;
      }
    }

    try {
      setIsLoading(true); // Show loading indicator

      const response = await fetch(audioUrl);
      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }

      const arrayBuffer = await response.arrayBuffer();
      let audioBuffer = await audioContextRef.current.decodeAudioData(arrayBuffer);
      setAudioBuffer(audioBuffer);

      //console.log('Audio file successfully loaded:', audioBuffer);
    } catch (error) {
      console.error('Error loading audio:', error);
    } finally {
      setIsLoading(false); // Loading complete
    }
  };

  const playAudioForWord = (word, startTime, endTime) => {
    if (!audioUrl) {
      //console.error('Audio URL is not defined.', startTime, endTime);
      return;
    }

    if (isRestoreBtnClicked) {
      isRestoreBtnClicked = false;
      return;
    }
    // Load the audio file if not loaded already
    //console.log('word', word);
    if (audioBuffer) {
      playSegment(startTime, endTime);
      audioPlayerRef.current.audio.current.currentTime = startTime;
    }
    else {
      alert('Please Note:Audio is not loaded yet. This message will only appear the first time you click the word. Please wait a moment while we prepare the audio for playback.');
      loadAudioFile(audioUrl).then(() => {
        //setIsLoading(true); // Hide loading indicator
        playSegment(startTime, endTime);
      });
    }
  };

  const playSegment = (startTime, endTime) => {
    const duration = endTime - startTime;

    if (!audioBuffer) {
      console.log('Audio not ready yet.');
      return;
    }

    // Stop any previously playing audio
    if (source) {
      source.stop();
    }

    // Create a new audio source from the buffer
    const source1 = audioContextRef.current.createBufferSource();

    source1.buffer = audioBuffer;
    source1.connect(audioContextRef.current.destination);
    //console.log('Audio source:', source1);
    // Start the audio from the startTime and play for the word's duration
    source1.start(0, startTime, duration);

    //console.log(`Playing from ${startTime} to ${endTime}`);

    // Handle when the playback ends
    source1.onended = () => {
      console.log('Playback ended after word');
    };
    setIsLoading(false);
  };

  const handleKeyPress = async (event) => {
    if (event.key === "Enter") {
      await handleBlur();
    }
  };

  const handleClick = (event, file) => {
    console.log('File:', file);
    setAnchorEl(event.currentTarget);
    setSelectedFile(file);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorElSpeaker(null);
    setSelectedSegmentIndex(null);
  };

  const parseTranscription = (rawDetails, transcriptionWords) => {
    const segments = [];

    if (rawDetails == '') return segments;

    rawDetails = rawDetails.replace(/\n+/g, '\n').trim();

    const speakerRegex = /([\w\s]+):\s(.*?)(?:<ts#0x1>(.*?)<\/ts#0x1>)/gs;
    let combinedMatch;

    while ((combinedMatch = speakerRegex.exec(rawDetails)) !== null) {
      const speakerOnly = combinedMatch[1].trim(); // Get speaker name
      const speakerText = combinedMatch[2].trim(); // Get speaker's spoken text
      const segmentDetails = combinedMatch[3] ? combinedMatch[3].trim() : ""; // Segment details if available

      let startTime;
      let segmentMatch;
      let originalParagraph;
      let isModifiedParagraph = false;
      let paragraphTranscriptionWordId;
      const segmentRegex = /([^,]+),?\s*\[(\d+(\.\d+)?)s\s*-\s*(\d+(\.\d+)?)s]/g;

      // Create a temporary object to store the speaker and their words
      const speakerSegments = {
        speakerOnly,
        speakerText,
        startTime,
        originalParagraph,
        isModifiedParagraph,
        paragraphTranscriptionWordId,
        words: [] // Store all words for this speaker
      };

      while ((segmentMatch = segmentRegex.exec(segmentDetails)) !== null) {
        const word = segmentMatch[1].trim(); // Extract the word
        const wordStartTime = parseFloat(segmentMatch[2]);
        const wordEndTime = parseFloat(segmentMatch[4]);

        const combinedTimestamp = '[' + wordStartTime + ' - ' + wordEndTime + ']';
        const foundWord = transcriptionWords.filter(item => item.customTimestamp == combinedTimestamp);
        let hasEditedWord = foundWord.length > 0 ;

        if(foundWord.length > 0)
        {
          // if flagged as modified paragraph make it false to prevent duplicate undo button
          hasEditedWord = foundWord[0].isModifiedParagraph ? false : hasEditedWord
        }

        speakerSegments.words.push({
          word,
          startTime: wordStartTime,
          endTime: wordEndTime,
          isEdited: hasEditedWord, 
          originalWord: hasEditedWord ? foundWord[0].originalWord : '',
          transcriptionWordId: hasEditedWord ? foundWord[0].id : 0
        });
      }

      // Push the speaker and their words as one entry
      speakerSegments.startTime = timeConverter(speakerSegments.words[0].startTime);

      const hasModifiedParagraph = transcriptionWords.filter(item => item.isModifiedParagraph);

      if (hasModifiedParagraph.length > 0) {
        const combinedParagraphTimestamp = '[' + speakerSegments.words[0].startTime + ' - ' + speakerSegments.words[speakerSegments.words.length - 1].endTime + ']';

        const originalParagraph = transcriptionWords.filter(item => item.originalTimestamp == combinedParagraphTimestamp);

        if (originalParagraph.length > 0) {
          speakerSegments.originalParagraph = originalParagraph[0].originalWord;
          speakerSegments.paragraphTranscriptionWordId = originalParagraph[0].id;
          speakerSegments.isModifiedParagraph = true;
        }
      }

      segments.push(speakerSegments);
    }

    // Regex to capture speaker's label, text, and segment details (optional)
    // const speakerRegex = /([^:]+):\s*([^([]+)(?:\(([^)]+)\))?/g;
    // const speakerRegex = /([^:]+):\s*([^(]+)(?:\(([^)]+)\))?/g;
    // let combinedMatch;
    // while ((combinedMatch = speakerRegex.exec(rawDetails)) !== null) {
    //   //console.log('Combined Match:', combinedMatch);
    //   const speakerOnly = combinedMatch[1].trim(); // Get speaker name
    //   const speakerText = combinedMatch[2].trim(); // Get speaker's spoken text
    //   const segmentDetails = combinedMatch[3] ? combinedMatch[3].trim() : ""; // Segment details if available

    //   // eslint-disable-next-line no-useless-escape
    //   // const segmentRegex = /([^,\[\]()]+),?\s*\[(\d+(\.\d+)?)s\s*-\s*(\d+(\.\d+)?)s\]/g;
    //   const segmentRegex = /(\[?[^[\],()]+\]?)\s*\[(\d+(\.\d+)?)s\s*-\s*(\d+(\.\d+)?)s\]/g;

    //   let startTime;
    //   let segmentMatch;

    //   // Create a temporary object to store the speaker and their words
    //   const speakerSegments = {
    //     speakerOnly,
    //     speakerText,
    //     startTime,
    //     words: [] // Store all words for this speaker
    //   };

    //   while ((segmentMatch = segmentRegex.exec(segmentDetails)) !== null) {
    //     const word = segmentMatch[1].trim(); // Extract the word
    //     const wordStartTime = parseFloat(segmentMatch[2]);
    //     const wordEndTime = parseFloat(segmentMatch[4]);

    //     const combinedTimestamp = '[' + wordStartTime + ' - ' + wordEndTime + ']';
    //     const foundWord = transcriptionWords.filter(item => item.customTimestamp == combinedTimestamp);
    //     const hasEditedWord = foundWord.length > 0;

    //     speakerSegments.words.push({
    //       word,
    //       startTime: wordStartTime,
    //       endTime: wordEndTime,
    //       isEdited: hasEditedWord,
    //       originalWord: hasEditedWord ? foundWord[0].originalWord : '',
    //       transcriptionWordId: hasEditedWord ? foundWord[0].id : 0
    //     });
    //   }
    //   // Push the speaker and their words as one entry
    //   speakerSegments.startTime = timeConverter(speakerSegments.words[0].startTime);
    //   segments.push(speakerSegments);
    // }
    return segments;
  };

  const onClickRetranscribe = async () => {
    if (selectedFile && selectedFile.transcriptions.length > 0) {
      const transcriptionId = selectedFile.transcriptions[0].id;
      console.log('Transcription ID:', transcriptionId);
      //await reTranscribe(selectedFile.id, transcriptionId);
      setModalRetranscribe(true);
      handleClose();


      setRefreshTrigger(prev => !prev);

      // retry get status 
      //await onCheckTranscriptionStatus(transcriptionId);

      setRefreshTrigger(prev => !prev);

    }
  }


  // const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
  // const onCheckTranscriptionStatus = async (transcriptionId, retryCount = 10) =>
  // {
  //   try
  //   {
  //       var response = await checkTranscriptionStatus(transcriptionId);
  //       console.log(response);
  //       if(response != 'Succeeded')
  //       {
  //         if(retryCount > 0)
  //         {
  //           await delay(2000);
  //           console.log("start delay");
  //           await onCheckTranscriptionStatus(transcriptionId, retryCount - 1);
  //         }
  //       }
  //   }
  //   catch(error)
  //   {
  //     console.log(error);
  //   }
  // }

  const handleTranscriptionClick = async () => {
    if (selectedFile && selectedFile.transcriptions.length > 0) {
      const isTranscriptionModified = selectedFile.transcriptions[0].modifiedDetails !== '';

      const rawDetails = isTranscriptionModified ? selectedFile.transcriptions[0].modifiedDetails : selectedFile.transcriptions[0].rawDetails;
      const transcriptionWords = selectedFile.transcriptions[0].transcriptionWords;
      const parsedSegments = parseTranscription(rawDetails, transcriptionWords);
      const transcriptionId = selectedFile.transcriptions[0].id;
      //console.log('Parsed Segments:', parsedSegments);

      try {
        const data = await getFileWithSasUrl(selectedFile.id);
        const savedButtons = await GetCustomButtons(user.email);
        console.log('Saved Buttons:', savedButtons);
        console.log('iconList', iconList);
        const newSavedButtons = savedButtons.map((btn) => {
          const selectedIconObj = iconList.find((iconObj) => iconObj.name === btn.icon);
          return { icon: selectedIconObj ? selectedIconObj.icon : null, text: btn.text, id: btn.id };
        });
        setCustomButtons(newSavedButtons);

       fetchChatHistory(transcriptionId);
        //setSuggestions(savedButtons);
        //console.log('DataSas', data);

        if (!data || !data.sasUrl) {
          console.error('Failed to retrieve SAS URL');
          return; // Early exit if SAS URL is not retrieved
        }

        setTranscriptionData(parsedSegments);
        setEditableText("");
        setModalOpen(true);

        // Check if convertedFileUrl is available
        if (selectedFile.convertedFileUrl) {
          //console.log('Using SAS URL for audio:', data.sasUrl);
          setAudioUrl(data.sasUrl);
          loadAudioFile(data.sasUrl);
        } else {
          console.error('convertedFileUrl is missing in selectedFile');
        }
      } catch (error) {
        console.error('Error getting file with SAS URL:', error);
      }
    } else {
      setTranscriptionData([]);
      setEditableText("No transcription available");
      setModalOpen(true);
    }
    handleClose();
  };


  const handleDeleteAudio = async () => {
    try {
      // Call the delete API
      const id = selectedFile.id;
      const url = selectedFile.url;
      const deleteResponse = await deleteAudio(id, url);
      console.log("ResponseDelete:", deleteResponse);
      setTableData(prevData => prevData.filter(fileRow => fileRow[6].id !== id));
      setAnchorEl(null);
      setOpenSuccessDeleteDialog(true);
    } catch (error) {
      console.error("Error deleting audio:", error);
    }
  };
  const handleModalClose = () => {
    if (source) {
      source.stop();  // Stop audio playback if playing
    }
    setModalOpen(false);
    setAudioUrl("");
    console.log('Modal closed');
    setAudioBuffer(null);
    setHighlightedSegmentIndex(null);
    setshowTranscribeTextTimestamp(false);
    setValue(0);
    setMessages([]);
  };

  const handleModalCloseSpeaker = () => {
    setModalOpenSpeaker(false);
  };

  const handleModalRetranscribeClose = () => {
    console.log('Modal Retranscribe Closed');
    setModalRetranscribe(false);
  };

  const handleRefresh = () => {
    setRefreshTrigger(prev => !prev);
  }

  // Fetch files by email using useEffect
  const updateStatusRetranscribe = async () => {
    const updateData = tableData.map((fileRow) => {
      if (fileRow[6].id === selectedFile.id) {
        return [
          ...fileRow.slice(0, 5),
          "Re Transcribing",
          fileRow[5]
        ];
      };

      return fileRow;
    })
    setTableData(updateData);
  }

  useEffect(() => {
    const fetchData = async () => {
      if (!user || !user.email) {
        console.error("User is not defined or email is missing.");
        return;
      }
      try {
   
        // Fetch files first
        const files = await fetchFilesByEmail(user.email);
        //console.log("Fetched files:", files); // Log files to inspect structure
        if (!Array.isArray(files)) {
          throw new Error("Expected files to be an array");
        }
        const formattedData = files.map((file) => {
          const transcriptionStatus = file.transcriptions.length > 0
            ? file.transcriptions[0].transcriptionStatus.name
            : "No transcription";
          return [
            file.audioTitleName || "Untitled",
            file.type || "Unknown",
            file.formattedSize || "Unknown size",
            formatDuration(file.duration) || "Unknown duration", // Convert to string
            formatDate(file.uploadedDate) || "Unknown date",
            transcriptionStatus,
            file
          ];
        });
        setTableData(formattedData);
        //console.log('Formatted Data:', formattedData);
        // Fetch converted file status and batch transcription only after files are fetched
        //const convertedFileResponse = await checkConvertedFile(user.email);
        await checkConvertedFile(user.email);
        //console.log("Converted file response:", convertedFileResponse); // Log response
        await checkRunningBatchTranscription(user.email);
        await checkRunningBatchReTranscription(user.email);
        //const batchTranscriptionResponse = await checkRunningBatchTranscription(user.email);
        //console.log("Batch transcription response:", batchTranscriptionResponse); // Log response
        //(files);
      } catch (error) {
        console.error("Error fetching data:", error);
      }

    };

    const updateInputWidth = () => {
      const input = inputRef.current;
      const span = spanRef.current;

      if (span && input) {
        // Set the input width based on the span width
        console.log(span.offsetWidth);
        input.style.width = `${span.offsetWidth + 10}px`; // Add some padding
      }
    };

    
    const updateParagraphInputWidth = () => {
      if (paragraphInputRef.current) {
        const newWidth = Math.max(50, paragraphInputRef.current.scrollWidth + 10);
        paragraphInputRef.current.style.width = `${newWidth}px`;
      }
    };



    fetchData();
    updateInputWidth();
    updateParagraphInputWidth();
    // Only log selectedSegmentIndex if it's not null
    if (selectedSegmentIndex !== null) {
      console.log('Updated selectedSegmentIndex:', selectedSegmentIndex);
      setSelectedSegmentIndexv2(selectedSegmentIndex);
    }

    if (highlightedSegmentIndex) {
      const activeSegment = document.getElementById(`segment-${highlightedSegmentIndex.startTime}`);
      if (activeSegment) {
        activeSegment.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      }
    }

    if (modalOpen) {
      window.addEventListener("keydown", handleKeyDown);
    } else {
      window.removeEventListener("keydown", handleKeyDown);
    }

    // Set up interval to run every 10 seconds
    const intervalId = setInterval(() => {
      fetchData();
    }, 10000); // 10 seconds

    // Clean up the interval when the component unmounts or dependencies change
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      clearInterval(intervalId);
    }

  }, [refreshTrigger, user, selectedEditText, selectedSegmentIndex, highlightedSegmentIndex, modalOpen, editableParagraph]);

  const handleKeyDown = (event) => {
    if (transcriptionContainerRef.current) {
      if (event.key === "ArrowDown") {
        transcriptionContainerRef.current.scrollBy({ top: 20, behavior: "smooth" });
      } else if (event.key === "ArrowUp") {
        transcriptionContainerRef.current.scrollBy({ top: -20, behavior: "smooth" });
      }
    }
  };

  const handleShowTranscribedTextTimestamp = (event) => {
    setshowTranscribeTextTimestamp(event.target.checked);
  }

  const timeConverter = (seconds) => {

    if (seconds > 60) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = Math.floor(seconds % 60);
      return `${minutes}m ${remainingSeconds}s`;
    }
    return `${seconds}s`;

  };

  const onRestoreOriginalWord = async (transcriptionWordId, originalWord, segmentIndex, wordIndex, isRestoreParagraph = false) => {
    isRestoreBtnClicked = true;

    const updatedTranscriptionData = [...transcriptionData];
    const transcriptionId = selectedFile.transcriptions[0].id;


    if (isRestoreParagraph) {
      updatedTranscriptionData[segmentIndex].speakerText = originalWord;
    }
    else {
      updatedTranscriptionData[segmentIndex].words[wordIndex].word = originalWord;
    }

    const parseTranscriptionDetails = await Promise.all(updatedTranscriptionData
      .map(async (segment) => {
        // Construct text from words if segment.text is undefined
        let segmentText = '';
        let wordsWithTime = '';

        if (isRestoreParagraph && segment.isModifiedParagraph) {
          segmentText = segment.speakerText;
          const paragraphStartTime = segment.words[0].startTime;
          const paragraphEndTime = segment.words[segment.words.length - 1].endTime;

          let originalSpeakerTimestamp = await getTranscriptionDetailsByTimestamp(transcriptionId, paragraphStartTime, paragraphEndTime);
          wordsWithTime = originalSpeakerTimestamp;

          return `${segment.speakerOnly}: ${segmentText} ${wordsWithTime}`;

        }
        else {
          segmentText = segment.text || segment.words.map(word => word.word).join(' ');
          wordsWithTime = segment.words
            .map((word) => `${word.word} [${word.startTime}s - ${word.endTime}s]`)
            .join(', ');

          return `${segment.speakerOnly}: ${segmentText} <ts#0x1>${wordsWithTime}</ts#0x1>`;
        }

      }));

    const fullRawDetails = parseTranscriptionDetails.join('\n');

    // call api to delete transcriptionWord by id
    if (transcriptionWordId > 0) {
      var transcriptionWords = await deleteTranscriptionWord(transcriptionWordId, transcriptionId)

      await saveUpdatedTranscription(transcriptionId, fullRawDetails);

      const parsedSegments = parseTranscription(fullRawDetails, transcriptionWords);

      setTranscriptionData(parsedSegments);

      // Trigger refresh
      setRefreshTrigger(prev => !prev);
    }
  }

  const handleEditParagraph = (segment, segmentIndex) => {
    const display = true;
    setIsParagraphEditing({ segmentIndex, display }); // Track both segment and word index

    setEditableParagraph(segment.speakerText)

  }

  const handleCloseEditParagraph = () => {
    setIsParagraphEditing(false); // Track both segment and word index
    setEditableParagraph(null);
  }

  const handleSaveParagraph = async (selectedSegment, segmentIndex) => {
    setsaveBntDisabled(true);
    const updatedTranscriptionData = [...transcriptionData];

    let transcriptionWordId = 0;
    const id = selectedFile.transcriptions[0].id;

    let originalWord = updatedTranscriptionData[segmentIndex].speakerText;

    originalWord = originalWord == '' ? updatedTranscriptionData[segmentIndex].speakerText : originalWord;

    const combinedTimestamp = '[' + updatedTranscriptionData[segmentIndex].words[0].startTime + ' - ' + updatedTranscriptionData[segmentIndex].words[updatedTranscriptionData[segmentIndex].words.length - 1].endTime + ']'

    if (selectedFile.transcriptions[0].transcriptionWords.length > 0) {
      let transcriptionWordObj = selectedFile.transcriptions[0].transcriptionWords.filter(f => f.originalWord == originalWord && f.originalTimestamp == combinedTimestamp);

      if (transcriptionWordObj.length > 0) {
        transcriptionWordId = transcriptionWordObj[0].id;
      }
    }

    const payloadTranscriptionWord =
    {
      id: transcriptionWordId,
      transcriptionId: id,
      originalWord: originalWord,
      originalTimestamp: combinedTimestamp,
      customWord: editableParagraph,
      customTimestamp: combinedTimestamp,
      email: user.email,
      IsModifiedParagraph: true
    }

    let transcriptionWordResult = await UpsertTranscriptionWord(payloadTranscriptionWord);

    const speakerOnly = selectedSegment.speakerOnly;
    const speakerText = editableParagraph;
    const startTime = selectedSegment.startTime;

    const speakerSegments = {
      speakerOnly,
      speakerText,
      startTime,
      words: []
    };

    speakerSegments.words = selectedSegment.words;

    updatedTranscriptionData[segmentIndex] = speakerSegments;

    const fullRawDetails = updatedTranscriptionData
      .map((segment) => {

        const totalWords = segment.speakerText.split(' ').length;
        const paragraphStartTime = segment.words[0].startTime;
        const paragraphEndTime = segment.words[segment.words.length - 1].endTime;

        const durationPerWord = totalWords > 0 ? (paragraphEndTime - paragraphStartTime) / totalWords : 0;
        let wordsWithTime = '';

        if(updatedTranscriptionData[segmentIndex].words[0].startTime == paragraphStartTime && 
          updatedTranscriptionData[segmentIndex].words[updatedTranscriptionData[segmentIndex].words.length - 1].endTime == paragraphEndTime)
        {

          wordsWithTime  = segment.speakerText.split(' ')
          .map((word, index) => {
            const wordStartTime = (paragraphStartTime + index * durationPerWord).toFixed(2);
            const wordEndTime = (paragraphStartTime + (index + 1) * durationPerWord).toFixed(2);
            
            return `${word} [${wordStartTime}s - ${wordEndTime}s]`
          })
          .join(', ');
        }
        else
        {
          wordsWithTime = segment.words
          .map((word) => `${word.word} [${word.startTime}s - ${word.endTime}s]`)
          .join(', ');
        }

        return `${segment.speakerOnly}: ${segment.speakerText} <ts#0x1>${wordsWithTime}</ts#0x1>`;
      })
      .join('\n');


    await saveUpdatedTranscription(id, fullRawDetails);

    const transcriptionWords = transcriptionWordResult.data;

    const parsedSegments = parseTranscription(fullRawDetails, transcriptionWords, true);
    setTranscriptionData(parsedSegments);

    setRefreshTrigger(prev => !prev);
    handleCloseEditParagraph();
    setsaveBntDisabled(false);
  }

  const onMoveFileToFolder = () => 
  {
    if (selectedFile && selectedFile.transcriptions.length > 0) {

      setOpenMoveFileModal(true);

      setRefreshTrigger(prev => !prev);
      console.log(isOpenMoveFileModal);
    }
  }

  const handleCloseMoveFileModal = () =>
  {
    setOpenMoveFileModal(false);
  }

  return (

    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <h4 className={classes.cardTitleWhite}>Files</h4>
                <p className={classes.cardCategoryWhite}>
                  List of transcriptions
                </p>
              </div>
              <div>
                {/* <button className="uploadButton" onClick={UploadModal}>Add Audio File</button> */}
                {/* <Button startIcon={<CloudUpload />} className="btnUpload" variant="contained" onClick={UploadModal}>Upload</Button> */}
                <Button startIcon={<CloudUpload />} className="btnUpload" variant="contained" onClick={UploadModalV2}>Upload</Button>
              </div>
            </div>

          </CardHeader>
          <CardBody style={{ height: '62vh', overflowY: 'scroll' }}>
            {/* Table for displaying fetched files */}
            <Table
              tableHeaderColor="primary"
              tableHead={[
                "File Title",
                "File Type",
                "File Size",
                "Duration",
                "Upload Date",
                "Status",
                "Action",
              ]}
              tableData={tableData.map((fileRow) => [
                ...fileRow.slice(0, 5), // Extract all fields except the action column
                fileRow[5] === "Converting" || fileRow[5] === "Transcribing" || fileRow[5] === "Re Transcribing" ? (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {fileRow[5]} {/* Display the current status text */}
                    <CircularProgress size={20} sx={{ marginLeft: '8px' }} />
                  </Box>
                ) : (
                  fileRow[5] // Display the status as text when it's not converting or transcribing
                ),
                <IconButton
                  key={fileRow[0]}
                  className={classes.iconButton}
                  onClick={(event) => handleClick(event, fileRow[6])}
                >
                  <MoreVertIcon />
                </IconButton>,
              ])}
            />
            {/* Modal to display transcription */}
            <Modal
              open={modalOpen}
              onClose={handleModalClose}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <div>{isLoading ? (
                <div style={{ marginTop: '20%', marginLeft: '40%', background: '#fff', width: '300px', padding: '10px', borderRadius: '10px' }}>
                  <p style={{ fontWeight: 'bolder', fontSize: '20px', marginRight: '10px' }}>Loading Audio<span className="dot">.</span><span className="dot">.</span><span className="dot">.</span></p>
                </div>
              ) : (

                <div className="modalContent">

                  {/* hidden span to be used for matching the input length to the selectedtext */}
                  <span
                    ref={spanRef}
                    style={{
                      visibility: "hidden",
                      position: "absolute",
                      whiteSpace: "pre",
                      fontSize: "16px", // Match the input font style
                    }}>
                    {selectedEditText || " "} {/* Ensure there's a space for empty input */}
                  </span>
                  <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <Tooltip title={<span style={{ fontSize: '1.5em' }}>Download Transcript</span>}>
                      <GetAppIcon style={{ marginRight: '5px', cursor: 'pointer', color: '#828787' }} onClick={downloadModal} />
                    </Tooltip>
                    <Tooltip title={<span style={{ fontSize: '1.5em' }}>Click on a word to play it, or right click to edit it</span>}>
                      <HelpOutlineIcon style={{ marginRight: '20px', color: '#828787' }} />
                    </Tooltip>
                    <button onClick={handleModalClose} className="modalButton">
                      X
                    </button>
                  </div>
                  <Box sx={styles}>
                    <Tabs value={value} onChange={handleTabChange} aria-label="tabs example">
                      <Tab label="Transcription" {...a11yProps(0)} />
                      <Tab label="Customize" {...a11yProps(1)} />
                    </Tabs>
                    {/* Tab Panels */}
                    <TabPanel value={value} index={0}>
                      {/* <Typography variant="h6" component="h2">
                          Transcription
                      </Typography> */}
                      {transcriptionData ? (
                        <div style={{ marginLeft: '-11px', marginBottom: '5px' }}>
                          <Checkbox
                            checked={showTranscribeTextTimestamp}
                            onChange={handleShowTranscribedTextTimestamp}></Checkbox> <small>Display Timestamp</small>
                        </div>) : (<div />)
                      }

                      {transcriptionData && transcriptionData.length > 0 ? (
                        <div className="transcriptionContainer" ref={transcriptionContainerRef} onKeyDown={handleKeyDown}>
                          {transcriptionData.map((segment, segmentIndex) => (

                            <div key={segmentIndex} style={{ marginBottom: '5px' }}>
                              {/* Display speaker label in a new line */}

                              <Tooltip placement='left' title="Right click to edit the speaker" arrow disablePortal={false}>
                                <Typography
                                  className="editParagraph"
                                  variant="h6"
                                  component="p"
                                  onContextMenu={(event) => editSpeaker(segment, segmentIndex, event)}
                                  style={{ marginTop: '5px', fontWeight: 'bold', clear: 'both', fontSize: '15px',
                                     display: isParagraphEditing?.segmentIndex === segmentIndex &&  editableParagraph.length > 50 ? 'block' : '' }} // Ensure label is separated from previous lines
                                >
                                  {showTranscribeTextTimestamp ? (
                                    <Link onClick={() => playAudioForWord(segment.words[0].word, segment.words[0].startTime, segment.words[0].endTime)} style={{ cursor: 'pointer', fontWeight: 'normal', fontStyle: 'italic', fontSize: '12px' }}
                                      underline="none">{segment.startTime}&nbsp;</Link>)
                                    : ''}

                                    {segment.speakerOnly}&nbsp;
                                 
                                </Typography>
                              </Tooltip>

                              {/* Display words for the current speaker */}
                              <div style={{
                                 display: 'inline', 
                                 maxWidth: '700px' ,
                                 color: segment.isModifiedParagraph ? 'rgba(255,64,129,1)' : '#3c4858',
                                 fontWeight: segment.isModifiedParagraph ? 'bold' : 'normal'}}
                                 className='transcriptionSegmentParagraph'>
                              {isParagraphEditing?.segmentIndex === segmentIndex ? (
                                <span style={{ display: 'inline-block' }}>
                                  <input 
                                  ref={paragraphInputRef}
                                  onChange={handleParagraphChange}
                                  value={editableParagraph}
                                  autoFocus
                                  style={{
                                    border: '1px solid #ccc',
                                    borderRadius: '4px',
                                  }}>
                                  </input>
                                  <Tooltip 
                                        title='Cancel'
                                        placement="bottom"
                                        arrow
                                        disablePortal={false}>
                                      <IconButton size="small">
                                        <Close size="small" onClick={handleCloseEditParagraph} style={{color : 'rgb(233, 30, 99)'}}></Close>
                                      </IconButton>
                                  </Tooltip>
                                  <Tooltip 
                                        title='Save'
                                        placement="bottom"
                                        arrow
                                        disablePortal={false}>
                                      <IconButton disabled={saveBntDisabled}>
                                        <Check style={{ color: 'rgb(46, 125, 50)' }} size='small'
                                          onClick={async () => await handleSaveParagraph(segment, segmentIndex)} />
                                      </IconButton>
                                    </Tooltip>
                                  </span>
                                )
                                  :
                                  (
                                    segment.words.map((wordSegment, wordIndex) => (
                                      <span key={wordIndex} style={{ display: 'inline-block' }}>
                                        {isEditing?.segmentIndex === segmentIndex && isEditing?.wordIndex === wordIndex ? (
                                          <input
                                            ref={inputRef}
                                            value={editableText}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            onKeyPress={handleKeyPress}
                                            autoFocus
                                            style={{
                                              display: 'inline-block',
                                              width: "1px",
                                              maxWidth: '100%', // Ensure it doesn't overflow the parent container
                                              cursor: 'pointer',
                                              boxSizing: 'border-box',
                                            }}
                                          />
                                        ) :
                                          (
                                            <Tooltip title={wordSegment.isEdited ? 'Original: ' + wordSegment.originalWord :
                                              segment.isModifiedParagraph ? 'Original: ' + segment.originalParagraph : ''
                                            }
                                              arrow
                                              disablePortal={false}>
                                              <Typography
                                                key={wordSegment.startTime}
                                                id={`segment-${wordSegment.startTime}`}
                                                variant="body2"
                                                component="span" // Change component to span for inline word behavior
                                                onContextMenu={(event) => handleDoubleClick(segmentIndex, wordIndex, wordSegment.startTime, wordSegment.endTime, event)}
                                                style={{
                                                  display: 'inline',
                                                  color: wordSegment.isEdited || segment.isModifiedParagraph ? 'rgba(255,64,129,1)' : '#3c4858',
                                                  fontWeight: wordSegment.isEdited || segment.isModifiedParagraph ? 'bold' : 'normal'
                                                }}
                                                className={`transcriptionSegment ${highlightedSegmentIndex &&
                                                  highlightedSegmentIndex.startTime <= wordSegment.endTime &&
                                                  highlightedSegmentIndex.endTime >= wordSegment.startTime
                                                  ? 'highlighted'
                                                  : ''
                                                  }`}
                                                onClick={() => {
                                                  // Only play audio if the word is not being edited
                                                  if (!(isEditing?.segmentIndex === segmentIndex && isEditing?.wordIndex === wordIndex)) {
                                                    playAudioForWord(wordSegment.word, wordSegment.startTime, wordSegment.endTime);
                                                  }
                                                }}
                                              >
                                                {wordSegment.word}
                                                {
                                                  (wordSegment.isEdited ?
                                                    <Tooltip arrow placement="right" title="Undo" disablePortal={false}>
                                                      <Undo className="word-icon" fontSize="small" disablePortal={true} onClick={() =>
                                                        onRestoreOriginalWord(wordSegment.transcriptionWordId, wordSegment.originalWord, segmentIndex, wordIndex)}></Undo>
                                                    </Tooltip> : '')
                                                }
                                                &nbsp;

                                              </Typography>
                                            </Tooltip>
                                          )
                                        }
                                      </span>
                                    ))
                                  )}
                                {
                                  (segment.isModifiedParagraph ?
                                        <Tooltip arrow placement="right" title="Undo" disablePortal={false}>
                                          <Undo className="paragraph-icon" fontSize="small" disablePortal={true} onClick={() =>
                                            onRestoreOriginalWord(segment.paragraphTranscriptionWordId, segment.originalParagraph, segmentIndex, 0, true)}
                                            ></Undo>
                                        </Tooltip> 
                                    : '')
                                }
                                  {(isParagraphEditing?.segmentIndex === segmentIndex ? '' : 
                                        <Tooltip arrow placement="right" title="Edit the line" disablePortal={false}>
                                            <Edit className="edit-paragraph-icon" 
                                            fontSize="small" 
                                            size="small" 
                                            disablePortal={true}
                                            onClick={() => handleEditParagraph(segment, segmentIndex)}></Edit>   
                                          </Tooltip>)}
                              </div>

                            </div>
                          ))}

                        </div>
                      ) : (
                        <Typography variant="body2">No transcription available</Typography>
                      )}
                      <div className="audioPlayerContainer">
                        {audioUrl && (
                          <AudioPlayer
                            ref={audioPlayerRef}
                            src={audioUrl}
                            autoPlay={false}
                            controls
                            customAdditionalControls={[]}
                            onPlay={() => {
                              audioPlayerRef.current.audio.current.addEventListener('timeupdate', handleTimeUpdate);
                            }}
                          />
                        )}
                      </div>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      <Box sx={{ width: 'auto', margin: '20px auto' }}>
                        {/* Display created buttons */}
                        <Box sx={{ marginTop: 10 }}>
                          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '10px' }}>
                            <div >
                              <Typography variant="subtitle1">AI Buttons</Typography>
                              <div>
                                <b>Note:</b>
                                <span style={{ marginLeft: '5px', fontSize: '14px', color: '#8f534e' }}>
                                  Right click to edit or delete AI button
                                </span>
                              </div>
                            </div>
                            <div>
                              <Tooltip style={{ fontSize: '14px' }} title="Add AI Button">
                                <Button variant="contained" appearance='primary' startIcon={<AddIcon />} onClick={handleCreateNewCustomButton} className="newCustomBtn"></Button>
                              </Tooltip>
                            </div>
                          </div>
                          <div className={`custom-btn-list ${customButtons.length === 0 ? 'no-border' : ''}`}>
                            {customButtons.map((btn, index) => (
                              <Tooltip style={{ fontSize: '14px' }} key={index} title={btn.text}>
                                <Button
                                  key={index}
                                  onClick={() => handleButtonClick(btn)}
                                  onContextMenu={(e) => handleEditDeleteCustomButton(e, btn, index)}
                                  variant="contained"
                                  color="cyan"
                                  appearance="primary"
                                  style={{ backgroundColor: '#37d1e7', color: '#3b3939', marginRight: '5px', marginBottom: '5px', textTransform: 'capitalize', padding: '4px' }}
                                >
                                  {btn.icon}
                                  {/* <span className="customBtnClose">
                                    <CloseIcon
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        removeButton(index);
                                      }}
                                      style={{ fontSize: '15px' }}
                                    />
                                  </span> */}
                                </Button>
                              </Tooltip>
                            ))}
                          </div>
                        </Box>
                        {/* Context menu for Edit and Delete */}
                        <Menu
                          open={contextMenu.mouseY !== null}
                          onClose={handleCloseContextMenu}
                          anchorReference="anchorPosition"
                          anchorPosition={
                            contextMenu.mouseY !== null && contextMenu.mouseX !== null
                              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                              : undefined
                          }
                        >
                          <MenuItem onClick={handleEdit}>
                            <EditIcon style={{ marginRight: '10px' }} /> Edit
                          </MenuItem>
                          <MenuItem onClick={handleDelete}>
                            <DeleteIcon style={{ marginRight: '10px' }} /> Delete
                          </MenuItem>
                        </Menu>
                        {/* Modal for editing the button */}
                        <Modal
                          open={isEditModalOpen}
                          onClose={handleCloseEditModal}
                          aria-labelledby="edit-button-modal-title"
                          aria-describedby="edit-button-modal-description"
                        >
                          <div className="modalContent" style={{ width: '400px' }}>

                            <Box sx={{
                              position: 'absolute', top: '50%', left: '50%',
                              transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper',
                              boxShadow: 24, p: 4
                            }}>
                              <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                <button onClick={handleCloseEditModal} className="modalButton">
                                  X
                                </button>
                              </div>
                              <Typography id="edit-button-modal-title" variant="h6" component="h2">
                                Edit Button Text
                              </Typography>
                              <TextField
                                fullWidth
                                value={newButtonText}
                                onChange={(e) => setNewButtonText(e.target.value)}
                                sx={{ mt: 2 }}
                              />
                              <Button sx={{ mt: 2 }} className="btnUpload" variant="contained" style={{ marginTop: '10px', float: 'right' }} onClick={handleSaveEdit}>
                                Update
                              </Button>
                            </Box>
                          </div>

                        </Modal>
                        {/* Chat Box */}
                        <Box sx={{ marginTop: 10, border: '1px solid #ccc', padding: '10px', borderRadius: '5px', height: '500px', overflowY: 'scroll' }}>
                          {messages.map((message, index) => (
                            <Box key={index} style={{ margin: '10px 0' }}>
                              {/* Display User Question */}
                              {message.type === 'user' && (
                                <Box style={{ display: 'flex', justifyContent: 'flex-end', margin: '10px 0' }}>
                                  <div>
                                    <Typography variant="caption" style={{ marginLeft: '10px', alignSelf: 'center', display: 'block', fontSize: '8px' }}>
                                      {new Date(message.timestamp).toLocaleString()}
                                    </Typography>
                                    <Typography
                                      variant="body1"
                                      style={{
                                        backgroundColor: '#37d1e7',
                                        color: '#3b3939',
                                        padding: '10px',
                                        borderRadius: '10px',
                                        fontSize: '12px',
                                        boxShadow: '1px 1px #7878'
                                      }}
                                    >
                                      {message.content}
                                    </Typography>
                                  </div>
                                </Box>
                              )}

                              {/* Display Azure Response */}
                              {message.type === 'azure' ? (
                                <Box style={{ display: 'block', justifyContent: 'flex-start', margin: '10px 0' }}>
                                  <Typography variant="caption" style={{ marginLeft: '10px', alignSelf: 'center', fontSize: '8px' }}>
                                    {new Date(message.timestamp).toLocaleString()}
                                  </Typography>
                                  <Typography
                                    variant="body1"
                                    style={{
                                      backgroundColor: 'rgb(255 255 255)',
                                      color: '#000',
                                      padding: '10px',
                                      borderRadius: '10px',
                                      maxWidth: '70%',
                                      fontSize: '12px',
                                      border: '1px solid #5454',
                                      boxShadow: '1px 1px #7878'
                                    }}
                                  >
                                    <ReactMarkdown>{message.response}</ReactMarkdown>
                                  </Typography>
                                </Box>
                              ) : index === messages.length - 1 && isResponseChat ? (
                                <Box style={{ display: 'flex', justifyContent: 'flex-start', margin: '10px 0' }}>
                                  <div className="dotloading">...</div>
                                </Box>
                              ) : null}
                            </Box>
                          ))}
                          <div ref={messageEndRef} />
                        </Box>
                      </Box>
                    </TabPanel>
                  </Box>
                </div>
              )}
              </div>
            </Modal>
            {/* Context Menu */}
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleTranscriptionClick}>
                View Transcription
              </MenuItem>
              <MenuItem onClick={onClickRetranscribe}>
                Re-Transcribe
              </MenuItem>
              <MenuItem onClick={onMoveFileToFolder}>
                Move to Folder
              </MenuItem>
              {/* <MenuItem disabled={true}>
                Share Transcription
              </MenuItem>
              <MenuItem disabled={true} onClick={handleDownloadTranscription}>
                Download Transcription
              </MenuItem>
              <MenuItem disabled={true}>
                Summarize Transcription
              </MenuItem> */}
              <MenuItem onClick={handleDeleteAudio}>
                Delete
              </MenuItem>
              {/* Add more MenuItems for Delete, Play */}
            </Menu>
            {/* Context Menu Edit Speaker*/}
            <Menu
              anchorEl={anchorElSpeaker}
              open={Boolean(anchorElSpeaker)}
              onClose={handleClose}>

              <MenuItem onClick={handleSingleSpeakeModal}>
                Edit Single Speaker
              </MenuItem>
              <MenuItem onClick={handleMultipleSpeakeModal}>
                Edit All Speakers
              </MenuItem>
            </Menu>
            {/* Speaker Name modal */}
            {modalOpenSpeaker && (<Modal
              open={modalOpenSpeaker}
              onClose={handleModalCloseSpeaker}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <div className="modalContent" style={{ width: '400px' }}>
                <div>
                  <h4>
                    {isSingleSpeaker ? "Edit Single Speaker" : isMutlipleSpeaker ? "Edit All Same Speaker" : null}
                  </h4>
                </div>
                <TextField
                  autoFocus
                  margin="dense"
                  label="Speaker Name"
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={speakerName}
                  onChange={(e) => setSpeakerName(e.target.value)}
                />
                <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'flex-end' }}>
                  <Button className="btnCancel" variant="contained" onClick={handleModalCloseSpeaker} style={{ marginRight: '10px' }}>
                    Cancel
                  </Button>
                  <Button className="btnUpload" variant="contained" onClick={() => {
                    if (isSingleSpeaker) {
                      handleSingleSpeakerEdit();
                    } else if (isMutlipleSpeaker) {
                      handleMultipleSpeakerEdit();
                    }
                  }} >
                    Save
                  </Button>
                </div>
              </div>
            </Modal>
            )}
            {/* new custom button modal */}
            <Modal
              open={modalOpenNewCustomButton}
              onClose={handleModalCloseNewCustomButton}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description">
              <div className="modalContent" style={{ width: '400px' }}>
                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                  <button onClick={handleModalCloseNewCustomButton} className="modalButton">
                    X
                  </button>
                </div>
                <Typography>Create a Question / Suggestion Button</Typography>

                {/* Icon Dropdown */}
                <FormControl fullWidth sx={{ marginBottom: 2 }}>
                  <InputLabel>Select an Icon</InputLabel>
                  <Select value={selectedIcon} onChange={handleIconChange} label="Select an Icon">
                    {iconList.map((iconObj, index) => (
                      <MenuItem key={index} value={iconObj.name}>
                        {iconObj.icon} {iconObj.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {/* TextField for Question or Action */}
                <TextField
                  fullWidth
                  label="Enter your question or suggestion"
                  value={questionText}
                  onChange={handleTextChange}
                  sx={{ marginBottom: 10 }}
                />
                {/* Suggested Words List */}
                {/* <div className="suggestionList">
                      {filteredSuggestions.length > 0 && (
                        <List sx={{ maxHeight: '100px', overflowY: 'auto', marginBottom: '10px', border: '1px solid #ccc', zIndex: 99999 }}>
                          {filteredSuggestions.map((btn, index) => (
                            <ListItem
                              key={index}
                              button
                              onClick={() => handleSuggestionClick(btn)}
                              sx={{ textTransform: 'capitalize', padding: '5px 10px' }}
                            >
                              {btn.text}
                            </ListItem>
                          ))}
                        </List>
                      )}
                      </div> */}

                {/* Button to create a new button */}
                <div style={{ marginTop: '10px', boxShadow: '1px 1px #dbd8db', borderRadius: '5px' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleCreateButton}
                    disabled={!selectedIcon || !questionText}
                  >
                    Create Button
                  </Button>
                </div>
              </div>
            </Modal>
          </CardBody>
        </Card>
      </GridItem>

      <Dialog
        open={openSuccessDialog}
        onClose={() => setOpenSuccessDialog(false)}>

        <DialogTitle></DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div style={{ display: 'inline-flex' }}>
              <Avatar style={{ backgroundColor: '#8bc34a' }}>
                <Check />
              </Avatar>
              <span style={{ marginTop: '10px', marginLeft: '10px' }}>File uploaded successfully!</span>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenSuccessDialog(false)} autoFocus>Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openSuccessDeleteDialog}
        onClose={() => setOpenSuccessDeleteDialog(false)}>

        <DialogTitle></DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div style={{ display: 'inline-flex' }}>
              <Avatar style={{ backgroundColor: '#8bc34a' }}>
                <Check />
              </Avatar>
              <span style={{ marginTop: '10px', marginLeft: '10px' }}>Audio deleted successfully!</span>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenSuccessDeleteDialog(false)} autoFocus>Close</Button>
        </DialogActions>
      </Dialog>
      {showExpiredModal && (
        <div className="modaltokenexpire">
          <div className="modal-contenttokenexpire">
            <h2>Session Expired</h2>
            <p>Your session has expired. Please log in again to continue.</p>
            <button onClick={handleModalCloseTokenExpired}>Ok</button>
          </div>
        </div>
      )}
      <div>
        <UploadFile isOpen={modalUploadv2} handleClose={handleModalUploadCloseV2} />
      </div>
      <div>
        <DownloadTranscirption isOpenDownload={isOpenDownloadModal} handleCloseDownload={handleModalDownloadClose} selectedFile={selectedFile} tableData={tableData} />
      </div>
      <div>
        <ReTranscribe isOpenRetranscribe={modalRetranscribe} handleCloseRetranscribe={handleModalRetranscribeClose} selectedFile={selectedFile} isRefresh={handleRefresh} updateStatusRetranscribe={updateStatusRetranscribe} />
      </div>
      <div>
        <MoveFile isOpenMoveFile={isOpenMoveFileModal} handleCloseMoveFile={handleCloseMoveFileModal} selectedFile={selectedFile} />
      </div>
    </GridContainer>

  );
}
