import React from 'react';
import Modal from '@material-ui/core/Modal';
import PropTypes from 'prop-types';
import '../../assets/css/audiolystyles.css';
import '../../assets/css/styles.scss'
import{ reTranscribeV2, getModelTranscriptionResource1 } from '../../services/api';
import { useState, useEffect } from 'react';
import { Button, FormControl, InputLabel, Select, MenuItem, CircularProgress } from '@material-ui/core';
import { useAuth } from '../../services/authContext';

const ReTranscribe = ({ isOpenRetranscribe, handleCloseRetranscribe, selectedFile,updateStatusRetranscribe }) => {
    const [selectedModel, setSelectedModel] = useState('');
    const [models, setModels] = useState([]);
    const [loading, setLoading] = useState(false);
    const { user } = useAuth();


    const fetchModels = async () => {
        try {
            setLoading(true);
            const modelsData = await getModelTranscriptionResource1();
            const sortedModels = modelsData.sort((a, b) =>
                b.displayName.localeCompare(a.displayName)
            );

            setModels(sortedModels);
            setSelectedModel(sortedModels[2]?.self || ''); 
        } catch (error) {
            console.error("Error fetching models:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchModels();
    }, []);
    const retranscribe = async () => {
        handleCloseRetranscribe();
        if (selectedFile && selectedFile.transcriptions.length > 0) {
            await updateStatusRetranscribe();
          const transcriptionId = selectedFile.transcriptions[0].id;
          await reTranscribeV2(selectedFile.id, transcriptionId, selectedModel, user.email);
          //await onCheckTranscriptionStatus(transcriptionId);
          //isRefresh();
          //await updateStatusRetranscribe();
        }
  
      }

    return (
        <Modal
            open={isOpenRetranscribe}
            onClose={handleCloseRetranscribe}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            BackdropProps={{
                onClick: (e) => e.stopPropagation(),
              }}
        >
            <div className="modalContent"
                style={{
                    width: '300px',
                    backgroundColor: 'white',
                    padding: '20px',
                    borderRadius: '8px',
                    margin: 'auto',
                }}>
                <div>
                    <button
                        onClick={handleCloseRetranscribe}
                        className="modalBtnClose"
                    >
                        &times;
                    </button>
                </div>
                <div style={{ display: 'flex' }}>
                    <div style={{ marginBottom: '10px', width: '100%' }}>
                        <FormControl fullWidth margin="normal">
                            <InputLabel id="modelSelectLabel">Select Custom Model</InputLabel>
                            <Select
                                labelId="modelSelectLabel"
                                id="modelSelect"
                                value={selectedModel}
                                onChange={(e) => setSelectedModel(e.target.value)} // Update selected model
                            >
                                {loading ? (
                                    <MenuItem disabled>
                                        <CircularProgress size={24} style={{ marginRight: '10px' }} />
                                        Loading models...
                                    </MenuItem>
                                ) : (
                                    models.map((model) => (
                                        <MenuItem key={model.self} value={model.self}>
                                            {model.displayName}
                                        </MenuItem>
                                    ))
                                )}
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div style={{ float: 'right' }}>
                    <Button className="btnUpload" variant="contained" onClick={retranscribe} >
                        Re Transcribes
                    </Button>
                </div>
            </div>
        </Modal>
    );
}

ReTranscribe.propTypes = {
    isOpenRetranscribe: PropTypes.bool.isRequired,
    handleCloseRetranscribe: PropTypes.func.isRequired,
    //isRefresh: PropTypes.func.isRequired,
    selectedFile: PropTypes.object.isRequired,
    updateStatusRetranscribe: PropTypes.func.isRequired
};

export default ReTranscribe;