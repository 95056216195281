export const msalConfig = {
    auth: 
    {
        clientId: 'dc5e2d70-3f0c-4bde-bb06-601713291426',
        //redirectUri: 'http://localhost:3000/',
        redirectUri: 'https://audioly.qtx.group/',
        authority: 'https://login.microsoftonline.com/common'
    },
    cache:
    {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false,
    }
};

export const msalLoginRequest = {
    scopes: ["User.Read"], // Scopes to request access to Microsoft Graph or custom APIs
  };