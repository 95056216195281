// src/contexts/AuthContext.js
import React, { createContext, useState, useContext,useEffect } from 'react';
import PropTypes from 'prop-types'; // Import PropTypes
//import Cookies from 'js-cookie';
// Create Context
const AuthContext = createContext();

// Create a Provider component
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, [])
  // Function to set user data
  const login = (userData) => {
    localStorage.setItem('user', JSON.stringify(userData));
    setUser(userData);
  };
  // Function to clear user data
  const logout = () => {
    localStorage.removeItem('user');
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

// Define PropTypes for AuthProvider
AuthProvider.propTypes = {
  children: PropTypes.node.isRequired, // Validate that children is a required node
};

// Custom hook to use the AuthContext
export const useAuth = () => {
  return useContext(AuthContext);
};
