import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
// import CustomInput from "components/CustomInput/CustomInput.js";
// import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
// import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
// import { useAuth } from '../../services/authContext';
//import avatar from "assets/img/faces/marc.jpg";
import { Button, Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import { Save } from '@material-ui/icons';

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);

export default function UserProfile() {
  // const { user } = useAuth();
  const classes = useStyles();
  const [dropdownValue, setDropdownValue] = React.useState(10);

  const handleDropdownChange = (event) => {
    setDropdownValue(event.target.value);
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Edit Settings</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControl fullWidth>
                    <InputLabel id="dropdown-label">Data location</InputLabel>
                    <Select
                      labelId="dropdown-label"
                      id="dropdown"
                      value={dropdownValue}
                      onChange={handleDropdownChange}
                    >
                      <MenuItem value={10} defaultValue={true}>Australia</MenuItem>
                      <MenuItem value={20}>UK</MenuItem>
                      <MenuItem value={30}>USA</MenuItem>
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>
            </CardBody>
          <CardFooter>
            <Button color="primary" variant="contained" disabled startIcon={<Save/>} style={{textTransform: 'none'}}>
              <span style={{paddingTop: '1px'}}>Save</span>
            </Button>
          </CardFooter>
        </Card>
      </GridItem>
    </GridContainer>
  </div>
);
}