export const  parseTranscription = (rawDetails, transcriptionWords) => {

  const timeConverter = (seconds) => {

    if (seconds > 60) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = Math.floor(seconds % 60);
      return `${minutes}m ${remainingSeconds}s`;
    }
    return `${seconds}s`;

  };
  const segments = [];

  if (rawDetails == '') return segments;

  rawDetails = rawDetails.replace(/\n+/g, '\n').trim();

  //const speakerRegex = /([\w\s]+):\s(.*?)(?:<ts#0x1>(.*?)<\/ts#0x1>)/gs;
  const speakerRegex = /([\w\s'’]+):\s(.*?)(?:<ts#0x1>(.*?)<\/ts#0x1>)/gs;
  

  let combinedMatch;

  while ((combinedMatch = speakerRegex.exec(rawDetails)) !== null) {
    const speakerOnly = combinedMatch[1].trim(); // Get speaker name
    const speakerText = combinedMatch[2].trim(); // Get speaker's spoken text
    const segmentDetails = combinedMatch[3] ? combinedMatch[3].trim() : ""; // Segment details if available

    let startTime;
    let segmentMatch;
    let originalParagraph;
    let isModifiedParagraph = false;
    let paragraphTranscriptionWordId;
    //const segmentRegex = /([^,]+),?\s*\[(\d+(\.\d+)?)s\s*-\s*(\d+(\.\d+)?)s]/g;
    const segmentRegex = /([^,]+),?\s*\[(\d+(\.\d+)?)s\s*-\s*(\d+(\.\d+)?)s]/g;
    const cleanedSpeakerText = speakerText
    .split(' ') 
    .filter(word => word !== "[deleted]") 
    .join(' '); 
    const speakerTextWithSpace = (speakerText === "[deleted]" || speakerText === "[deletedline]") ? "" : speakerOnly;
    // Create a temporary object to store the speaker and their words
    const speakerSegments = {
      speakerOnly:speakerTextWithSpace,
      speakerText:cleanedSpeakerText,
      startTime,
      originalParagraph,
      isModifiedParagraph,
      paragraphTranscriptionWordId,
      words: [] // Store all words for this speaker
    };

    while ((segmentMatch = segmentRegex.exec(segmentDetails)) !== null) {
      const word = segmentMatch[1].trim();  // Extract the word
      const wordStartTime = parseFloat(segmentMatch[2]);
      const wordEndTime = parseFloat(segmentMatch[4]);

      const combinedTimestamp = '[' + wordStartTime + ' - ' + wordEndTime + ']';
      const foundWord = transcriptionWords.filter(item => item.customTimestamp == combinedTimestamp);
      let hasEditedWord = foundWord.length > 0;

      if (foundWord.length > 0) {
        // if flagged as modified paragraph make it false to prevent duplicate undo button
        hasEditedWord = foundWord[0].isModifiedParagraph ? false : hasEditedWord
      } // If empty, make it a regular space
      const wordWithSpace = (word === "[deleted]" || word === "[deletedline]") ? "" : word;

      speakerSegments.words.push({
        word: wordWithSpace,
        startTime: wordStartTime,
        endTime: wordEndTime,
        isEdited: hasEditedWord,
        originalWord: hasEditedWord ? foundWord[0].originalWord : '',
        transcriptionWordId: hasEditedWord ? foundWord[0].id : 0
      });
    }

    // Push the speaker and their words as one entry
    speakerSegments.startTime = timeConverter(speakerSegments.words[0].startTime);

    const hasModifiedParagraph = transcriptionWords.filter(item => item.isModifiedParagraph);

    if (hasModifiedParagraph.length > 0) {
      const combinedParagraphTimestamp = '[' + speakerSegments.words[0].startTime + ' - ' + speakerSegments.words[speakerSegments.words.length - 1].endTime + ']';

      const originalParagraph = transcriptionWords.filter(item => item.originalTimestamp == combinedParagraphTimestamp);

      if (originalParagraph.length > 0) {
        speakerSegments.originalParagraph = originalParagraph[0].originalWord;
        speakerSegments.paragraphTranscriptionWordId = originalParagraph[0].id;
        speakerSegments.isModifiedParagraph = true;
      }
    }

    segments.push(speakerSegments);
  }
 
    // Regex to capture speaker's label, text, and segment details (optional)
    // const speakerRegex = /([^:]+):\s*([^([]+)(?:\(([^)]+)\))?/g;
    // const speakerRegex = /([^:]+):\s*([^(]+)(?:\(([^)]+)\))?/g;
    // let combinedMatch;
    // while ((combinedMatch = speakerRegex.exec(rawDetails)) !== null) {
    //   //console.log('Combined Match:', combinedMatch);
    //   const speakerOnly = combinedMatch[1].trim(); // Get speaker name
    //   const speakerText = combinedMatch[2].trim(); // Get speaker's spoken text
    //   const segmentDetails = combinedMatch[3] ? combinedMatch[3].trim() : ""; // Segment details if available

    //   // eslint-disable-next-line no-useless-escape
    //   // const segmentRegex = /([^,\[\]()]+),?\s*\[(\d+(\.\d+)?)s\s*-\s*(\d+(\.\d+)?)s\]/g;
    //   const segmentRegex = /(\[?[^[\],()]+\]?)\s*\[(\d+(\.\d+)?)s\s*-\s*(\d+(\.\d+)?)s\]/g;

    //   let startTime;
    //   let segmentMatch;

    //   // Create a temporary object to store the speaker and their words
    //   const speakerSegments = {
    //     speakerOnly,
    //     speakerText,
    //     startTime,
    //     words: [] // Store all words for this speaker
    //   };

    //   while ((segmentMatch = segmentRegex.exec(segmentDetails)) !== null) {
    //     const word = segmentMatch[1].trim(); // Extract the word
    //     const wordStartTime = parseFloat(segmentMatch[2]);
    //     const wordEndTime = parseFloat(segmentMatch[4]);

    //     const combinedTimestamp = '[' + wordStartTime + ' - ' + wordEndTime + ']';
    //     const foundWord = transcriptionWords.filter(item => item.customTimestamp == combinedTimestamp);
    //     const hasEditedWord = foundWord.length > 0;

    //     speakerSegments.words.push({
    //       word,
    //       startTime: wordStartTime,
    //       endTime: wordEndTime,
    //       isEdited: hasEditedWord,
    //       originalWord: hasEditedWord ? foundWord[0].originalWord : '',
    //       transcriptionWordId: hasEditedWord ? foundWord[0].id : 0
    //     });
    //   }
    //   // Push the speaker and their words as one entry
    //   speakerSegments.startTime = timeConverter(speakerSegments.words[0].startTime);
    //   segments.push(speakerSegments);
    // }
    return segments;
  };

